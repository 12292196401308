<template>
  <div>
    <EditDialog width="80%" :isReturn="true" @closeFn="returnDialogFn" :isShow.sync="costDialog">
      <div slot="title" class="title">{{ options.costType }}详情</div>
      <div slot="content" class="content" style="min-height: 400px" v-loading="loading">
        <!-- 项目报销详情 -->
        <template v-if="options.costType == '项目报销'">
          <el-descriptions direction="vertical" :column="6" border style="width: 100%">
            <el-descriptions-item label="单据编号">
              {{ form.expenseNumber }}
            </el-descriptions-item>

            <el-descriptions-item label="申请人">
              {{ form.createName }}
            </el-descriptions-item>

            <el-descriptions-item label="部门">
              {{ form.deptName }}
            </el-descriptions-item>

            <el-descriptions-item label="领款人">
              {{ form.payeeName }}
            </el-descriptions-item>

            <el-descriptions-item label="申请日期">
              {{ form.taskDate | dateFormat }}
            </el-descriptions-item>

            <el-descriptions-item label="费用所属单位">
              {{ form.companyType | dict(dictData.companyType) }}
            </el-descriptions-item>
            <el-descriptions-item
              :span="1"
              label-class-name="businessOutSource"
              content-class-name="businessOutSource"
            >
              <template slot="label">
                <span style="color: #000">费用类别</span>
              </template>
              <span style="color: #000; font-size: 14px">项目费用报销</span>
            </el-descriptions-item>

            <el-descriptions-item label="付款方式" :span="2">
              {{ form.applyExpenseType | dict(applyExpenseType) }}
            </el-descriptions-item>

            <el-descriptions-item label="报销方式" :span="3">
              {{ form.expenseMode | dict(expenseMode) }}
            </el-descriptions-item>
          </el-descriptions>

          <!-- 经费预支单据 -->
          <el-table
            v-if="form.counteractExpenseList && form.counteractExpenseList.length > 0"
            :data="form.counteractExpenseList"
            border
            style="margin-top: 10px"
            :row-style="{ height: '80px' }"
          >
            <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>
            <el-table-column
              align="center"
              prop="expenseNumber"
              min-width="160"
              label="单据编号"
            ></el-table-column>
            <el-table-column
              prop="taskDate"
              align="center"
              label="申请日期"
              min-width="110"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="cost" label="预支总金额">
              <template slot-scope="scope">
                {{ scope.row.cost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="sumNotCounteractCost">
              <template slot="header" slot-scope="scope">
                <div>未冲销金额</div>
                <!-- <div style="color: red">（包括审批中的金额）</div> -->
              </template>
              <template slot-scope="scope">
                {{ scope.row.sumNotCounteractCost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="cost"
              label="冲销金额"
              align="center"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">{{ scope.row.counteractCost | applyAmount }} </template>
            </el-table-column>
            <!-- <el-table-column align="center" label="操作" width="100" :show-overflow-tooltip="false">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="expenditureDetailFn(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column> -->
          </el-table>
          <el-descriptions title="" :column="2" border style="margin-top: 20px">
            <el-descriptions-item labelStyle="width:100px;" :span="1">
              <template slot="label"> 项目名称 </template>
              <span style="display: inline-block; line-height: 40px">{{ form.projectName }}</span>
            </el-descriptions-item>
            <el-descriptions-item labelStyle="width:110px;">
              <template slot="label"> 项目承接单位 </template>
              <div style="line-height: 40px">
                {{ form.projectCompanyType | dict(dictData.companyType) }}
              </div>
            </el-descriptions-item>
          </el-descriptions>
          <el-form class="column3 form_box table" label-position="top" style="width: 100%">
            <el-table :data="form.detailList" border :row-style="{ height: '80px' }">
              <el-table-column label="序号" align="center" type="index" width="70">
              </el-table-column>

              <el-table-column
                prop="taskDate"
                label="日期"
                width="120"
                align="center"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  {{ scope.row.taskDate | dateFormat }}
                </template>
              </el-table-column>
              <el-table-column
                prop="typeOne"
                label="费用科目一"
                width="170"
                :show-overflow-tooltip="false"
              >
                <template slot="header">
                  费用科目一
                  <div></div>
                </template>
                <template slot-scope="scope">
                  <Dictionary disabled v-model="scope.row.typeOne" code="CBFYZC" />
                </template>
              </el-table-column>
              <el-table-column
                prop="typeTwo"
                label="费用科目二"
                width="170"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  <Dictionary disabled v-model="scope.row.typeTwo" :code="scope.row.typeOne" />
                </template>
              </el-table-column>
              <el-table-column
                prop="typeThree"
                label="费用科目三"
                width="170"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  <Dictionary disabled v-model="scope.row.typeThree" :code="scope.row.typeTwo" />
                </template>
              </el-table-column>

              <el-table-column
                prop="cost"
                label="金额 (元)"
                width="120"
                align="center"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.cost | applyAmount }}</span>
                </template>
              </el-table-column>
              <el-table-column
                scoped-slot
                prop="contentDetail"
                :label="'事由附件详细说明'"
                width="220"
                :show-overflow-tooltip="false"
              >
              </el-table-column>
              <el-table-column
                prop="estimatedRefundDate"
                label="预计退回时间"
                width="160"
                v-if="EstimatedShow"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  <template
                    v-if="
                      scope.row.typeTwo === 'tou_bao_zheng_jin' ||
                      scope.row.typeTwo === 'lv_yue_bao_zheng_jin'
                    "
                  >
                    {{ scope.row.estimatedRefundDate | dateFormat }}
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                align="left"
                min-width="340"
                label="发票附件上传"
                :show-overflow-tooltip="false"
              >
                <template slot="header">
                  <div>发票附件上传</div>
                  <div>只能上传pdf或图片.png .jpg .jpeg格式</div>
                </template>
                <template slot-scope="scope">
                  <div
                    v-for="(n, index) in scope.row.expenseFileList"
                    :key="index"
                    class="invoice-file"
                  >
                    <template v-if="n.fileType === 'INVOICE'">
                      <div class="invoice-file-left">
                        <div>
                          <a
                            v-if="isShowType(n)"
                            style="text-decoration: revert; color: #409eff; padding-right: 8px"
                            target="_blank"
                            :href="`${filepath}${n.filePath}`"
                          >
                            {{ n.fileName }}
                          </a>
                          <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                            n.fileName
                          }}</span>
                        </div>
                        <div>
                          <span style="color: red" v-if="n.fileInvoiceNoType === 'EXIST'"
                            >发票识别存疑</span
                          >
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="left"
                width="330"
                label="非发票附件上传"
                :show-overflow-tooltip="false"
              >
                <template slot-scope="scope">
                  <div
                    v-for="(n, index) in scope.row.expenseFileList"
                    :key="index"
                    style="margin-top: 5px"
                  >
                    <template v-if="n.fileType === 'NORMAL'">
                      <a
                        v-if="isShowType(n)"
                        style="text-decoration: revert; color: #409eff; padding-right: 8px"
                        target="_blank"
                        :href="`${filepath}${n.filePath}`"
                      >
                        {{ n.fileName }}
                      </a>
                      <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                        n.fileName
                      }}</span>
                    </template>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form>

          <div style="height: 20px"></div>

          <el-descriptions title="" :column="4" border>
            <el-descriptions-item
              label="原登记单总金额"
              v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'"
              labelStyle="width:120px"
            >
              {{ form.registrationExpenditureTotalCost | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item
              label="报销合计"
              :span="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT' ? 1 : 2"
              labelStyle="width:120px"
            >
              {{ amount | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item label="金额大写" :span="2" labelStyle="width:120px">
              {{ amount | numberParseChina }}
            </el-descriptions-item>
            <template v-if="form.applyExpenseType == 'ADVANCE_PAYMENT'">
              <template v-if="form.status != 100">
                <el-descriptions-item label="总预支费用" labelStyle="width:120px">
                  {{ totalCounteract.sumAdvanceCost | applyAmount }}
                </el-descriptions-item>
                <el-descriptions-item labelStyle="width:150px">
                  <template slot="label">
                    <div>　冲销中的金额</div>
                    <div>（包括本单的冲销）</div>
                  </template>

                  {{ writeOffMoney | applyAmount }}
                </el-descriptions-item></template
              >
              <el-descriptions-item
                label="本次冲销总金额"
                :span="form.status != 100 ? 1 : 2"
                labelStyle="width:120px"
              >
                {{ thisTimeMoney | applyAmount }}
              </el-descriptions-item>
              <el-descriptions-item
                label="报销支付金额"
                :span="form.status != 100 ? 1 : 2"
                labelStyle="width:120px"
              >
                <template v-if="paymentMony">
                  {{ paymentMony | applyAmount }}
                </template>
                <template v-else> 0 </template>
              </el-descriptions-item>
            </template>
          </el-descriptions>

          <el-descriptions
            :column="1"
            direction="vertical"
            style="margin-top: 10px"
            border
            v-if="form.remark"
          >
            <el-descriptions-item>
              <template slot="label">
                <span style="color: #0066ff; font-size: 18px"> 财务确认发放备注 </span>
              </template>
              {{ form.remark }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <span style="color: #0066ff; font-size: 18px"> 发放附件 </span>
              </template>
              <template v-for="item in form.affirmCostFileList">
                <a
                  :key="item.id"
                  target="_blank"
                  style="text-decoration: revert; color: #409eff; display: inline-block; width: 31%"
                  :href="`${filepath}${item.filePath}`"
                  >{{ item.fileName }}</a
                >
              </template>
            </el-descriptions-item>
          </el-descriptions>
          <div style="height: 40px"></div>

          <OpinionArea
            ref="opinionArea"
            :procInstId="form.processInstId"
            :instInvolved="instInvolved"
            :title="'项目报销进度'"
          ></OpinionArea>
        </template>

        <!-- 商务报销详情 -->
        <template v-if="options.costType == '商务报销'">
          <el-descriptions direction="vertical" :column="6" border style="width: 100%">
            <el-descriptions-item label="单据编号">
              {{ form.expenseNumber }}
            </el-descriptions-item>
            <el-descriptions-item label="申请人">
              {{ form.createName }}
            </el-descriptions-item>

            <el-descriptions-item label="部门">
              {{ form.deptName }}
            </el-descriptions-item>
            <el-descriptions-item label="领款人">
              {{ form.payeeName }}
            </el-descriptions-item>

            <el-descriptions-item label="申请日期">
              {{ form.taskDate | dateFormat }}
            </el-descriptions-item>

            <el-descriptions-item label="费用所属单位">
              {{ form.companyType | dict(dictData.companyType) }}
            </el-descriptions-item>
            <el-descriptions-item
              :span="1"
              label-class-name="businessOutSource"
              content-class-name="businessOutSource"
            >
              <template slot="label">
                <span style="color: #000">费用类别</span>
              </template>
              <span style="color: #000; font-size: 17px">商务费用报销</span>
            </el-descriptions-item>

            <el-descriptions-item label="付款方式" :span="2">
              {{ form.applyExpenseType | dict(applyExpenseType) }}
            </el-descriptions-item>

            <el-descriptions-item label="报销方式" :span="2">
              {{ form.expenseMode | dict(expenseMode) }}
            </el-descriptions-item>
          </el-descriptions>

          <!-- 经费预支单据 -->
          <el-table
            v-if="form.counteractExpenseList && form.counteractExpenseList.length > 0"
            :data="form.counteractExpenseList"
            border
            style="margin-bottom: 10px"
            :row-style="{ height: '80px' }"
          >
            <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>
            <el-table-column
              align="center"
              prop="expenseNumber"
              min-width="160"
              label="单据编号"
            ></el-table-column>
            <el-table-column
              prop="taskDate"
              align="center"
              label="申请日期"
              min-width="110"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="cost" label="预支总金额">
              <template slot-scope="scope">
                {{ scope.row.cost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="sumNotCounteractCost">
              <template slot="header" slot-scope="scope">
                <div>未冲销金额</div>
                <!-- <div style="color: red">（包括审批中的金额）</div> -->
              </template>
              <template slot-scope="scope">
                {{ scope.row.sumNotCounteractCost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="cost"
              label="冲销金额"
              align="center"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">{{ scope.row.counteractCost | applyAmount }} </template>
            </el-table-column>
            <!-- <el-table-column align="center" label="操作" width="100" :show-overflow-tooltip="false">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="expenditureDetailFn(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column> -->
          </el-table>

          <el-descriptions title="" :column="1" border>
            <el-descriptions-item :span="1" labelStyle="width:180px;">
              <template slot="label">支出类型</template>
              {{ form.disbursementType | dict(dictData.disbursementType) }}
            </el-descriptions-item>
            <el-descriptions-item labelStyle="width:100px;">
              <template slot="label">
                <template v-if="form.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT'">
                  商务说明</template
                >
                <template v-else> 商务名称</template>
              </template>
              <template v-if="form.disbursementType == 'UNDEFINED_RATIFICATION_IMPLEMENT'"
                ><span style="display: inline-block; line-height: 40px; width: 70%">{{
                  form.businessContent
                }}</span></template
              >
              <template v-else>
                <span style="display: inline-block; line-height: 40px; width: 70%">{{
                  form.businessName
                }}</span>
              </template>
            </el-descriptions-item>
          </el-descriptions>

          <el-table :data="form.detailList" border>
            <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>
            <el-table-column
              prop="taskDate"
              label="日期"
              min-width="170"
              align="center"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              prop="typeOne"
              label="费用科目一"
              width="170"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeOne" code="CBFYZC" />
              </template>
            </el-table-column>
            <el-table-column
              prop="typeTwo"
              label="费用科目二"
              width="170"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeTwo" :code="scope.row.typeOne" />
              </template>
            </el-table-column>
            <el-table-column
              prop="typeThree"
              label="费用科目三"
              width="170"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeThree" :code="scope.row.typeTwo" />
              </template>
            </el-table-column>
            <el-table-column
              prop="cost"
              label="金额 (元)"
              min-width="120"
              align="center"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.cost | applyAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              scoped-slot
              prop="contentDetail"
              label="事由附件详细说明"
              :show-overflow-tooltip="false"
            >
            </el-table-column>
            <!-- <el-table-column
              prop="estimatedRefundDate"
              label="预计退回时间"
              width="160"
              v-if="EstimatedShow"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <template
                  v-if="
                    scope.row.typeTwo === 'tou_bao_zheng_jin' ||
                    scope.row.typeTwo === 'lv_yue_bao_zheng_jin'
                  "
                >
              {{ scope.row.estimatedRefundDate | dateFormat }}
                </template>
              </template>
            </el-table-column> -->
            <el-table-column scoped-slot prop="expenseFileList" label="附件">
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  style="margin-top: 5px"
                >
                  <a
                    v-if="isShowType(n)"
                    style="text-decoration: revert; color: #409eff; padding-right: 8px"
                    target="_blank"
                    :href="`${filepath}${n.filePath}`"
                  >
                    {{ n.fileName }}
                  </a>
                  <span v-else class="img_txt" @click="imgIsShow(n, index)">{{ n.fileName }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <el-descriptions title="" :column="4" border>
            <el-descriptions-item
              label="原登记单总金额"
              v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'"
              labelStyle="width:120px"
            >
              {{ form.registrationExpenditureTotalCost | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item
              label="报销合计"
              :span="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT' ? 1 : 2"
              labelStyle="width:120px"
            >
              {{ amount | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item label="金额大写" :span="2" labelStyle="width:120px">
              {{ amount | numberParseChina }}
            </el-descriptions-item>
            <template v-if="form.applyExpenseType == 'ADVANCE_PAYMENT'">
              <template v-if="form.status != 100">
                <el-descriptions-item label="总预支费用" labelStyle="width:120px">
                  {{ totalCounteract.sumAdvanceCost | applyAmount }}
                </el-descriptions-item>
                <el-descriptions-item labelStyle="width:150px">
                  <template slot="label">
                    <div>　冲销中的金额</div>
                    <div>（包括本单的冲销）</div>
                  </template>

                  {{ writeOffMoney | applyAmount }}
                </el-descriptions-item></template
              >
              <el-descriptions-item
                label="本次冲销总金额"
                :span="form.status != 100 ? 1 : 2"
                labelStyle="width:120px"
              >
                {{ thisTimeMoney | applyAmount }}
              </el-descriptions-item>
              <el-descriptions-item
                label="报销支付金额"
                :span="form.status != 100 ? 1 : 2"
                labelStyle="width:120px"
              >
                <template v-if="paymentMony">
                  {{ paymentMony | applyAmount }}
                </template>
                <template v-else> 0 </template>
              </el-descriptions-item>
            </template>
          </el-descriptions>
          <el-descriptions
            :column="1"
            direction="vertical"
            style="margin-top: 10px"
            border
            v-if="form.remark"
          >
            <el-descriptions-item>
              <template slot="label">
                <span style="color: #0066ff; font-size: 18px"> 财务确认发放备注 </span>
              </template>
              {{ form.remark }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <span style="color: #0066ff; font-size: 18px"> 发放附件 </span>
              </template>
              <template v-for="item in form.affirmCostFileList">
                <a
                  :key="item.id"
                  target="_blank"
                  style="text-decoration: revert; color: #409eff; display: inline-block; width: 31%"
                  :href="`${filepath}${item.filePath}`"
                  >{{ item.fileName }}</a
                >
              </template>
            </el-descriptions-item>
          </el-descriptions>
          <div style="height: 40px"></div>
          <OpinionArea
            ref="opinionArea"
            :procInstId="form.processInstId"
            :instInvolved="instInvolved"
            :title="'商务报销进度'"
          ></OpinionArea>
        </template>

        <!-- 公共费用报销详情 -->
        <template v-if="options.costType == '公共报销'">
          <el-descriptions direction="vertical" :column="6" border style="width: 100%">
            <el-descriptions-item label="单据编号">
              {{ form.expenseNumber }}
            </el-descriptions-item>

            <el-descriptions-item label="申请人">
              {{ form.createName }}
            </el-descriptions-item>

            <el-descriptions-item label="费用所属部门">
              {{ form.deptName }}
            </el-descriptions-item>

            <el-descriptions-item label="领款人">{{ form.payeeName }} </el-descriptions-item>

            <el-descriptions-item label="申请日期">
              {{ form.taskDate | dateFormat }}
            </el-descriptions-item>

            <el-descriptions-item label="费用所属单位">
              {{ form.companyType | dict(dictData.companyType) }}
            </el-descriptions-item>

            <el-descriptions-item
              :span="1"
              label-class-name="businessOutSource"
              content-class-name="businessOutSource"
            >
              <template slot="label">
                <span style="color: #000">费用类别</span>
              </template>
              <span style="color: #000; font-size: 17px">公共费用报销</span>
            </el-descriptions-item>
            <el-descriptions-item label="付款方式" :span="2">
              {{ form.applyExpenseType | dict(applyExpenseType) }}
            </el-descriptions-item>

            <el-descriptions-item label="报销方式" :span="3">
              {{ form.expenseMode | dict(expenseMode) }}
            </el-descriptions-item>
          </el-descriptions>
          <!-- 经费预支单据 -->
          <el-table
            v-if="form.counteractExpenseList && form.counteractExpenseList.length > 0"
            :data="form.counteractExpenseList"
            border
            style="margin-bottom: 10px"
            :row-style="{ height: '80px' }"
          >
            <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>
            <el-table-column
              align="center"
              prop="expenseNumber"
              min-width="160"
              label="单据编号"
            ></el-table-column>
            <el-table-column
              prop="taskDate"
              align="center"
              label="申请日期"
              min-width="110"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="cost" label="预支总金额">
              <template slot-scope="scope">
                {{ scope.row.cost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="sumNotCounteractCost">
              <template slot="header" slot-scope="scope">
                <div>未冲销金额</div>
                <!-- <div style="color: red">（包括审批中的金额）</div> -->
              </template>
              <template slot-scope="scope">
                {{ scope.row.sumNotCounteractCost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="cost"
              label="冲销金额"
              align="center"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">{{ scope.row.counteractCost | applyAmount }} </template>
            </el-table-column>
            <!-- <el-table-column align="center" label="操作" width="100" :show-overflow-tooltip="false">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="expenditureDetailFn(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column> -->
          </el-table>

          <el-table :data="form.detailList" border :row-style="{ height: '80px' }">
            <el-table-column label="序号" align="center" type="index" width="70"> </el-table-column>

            <el-table-column
              prop="taskDate"
              label="日期"
              align="center"
              min-width="170"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              prop="typeOne"
              label="费用科目一"
              width="170"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeOne" code="CBFYZC" />
              </template>
            </el-table-column>
            <el-table-column
              prop="typeTwo"
              label="费用科目二"
              width="170"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeTwo" :code="scope.row.typeOne" />
              </template>
            </el-table-column>
            <el-table-column
              prop="typeThree"
              label="费用科目三"
              width="170"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <Dictionary disabled v-model="scope.row.typeThree" :code="scope.row.typeTwo" />
              </template>
            </el-table-column>

            <el-table-column
              prop="cost"
              align="center"
              label="金额 (元)"
              width="120"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">{{ scope.row.cost | applyAmount }} </template>
            </el-table-column>
            <el-table-column
              scoped-slot
              prop="contentDetail"
              :label="'事由附件详细说明'"
              width="220"
              :show-overflow-tooltip="false"
            >
            </el-table-column>
            <el-table-column
              prop="estimatedRefundDate"
              label="预计退回时间"
              width="160"
              v-if="EstimatedShow"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <template
                  v-if="
                    scope.row.typeTwo === 'tou_bao_zheng_jin' ||
                    scope.row.typeTwo === 'lv_yue_bao_zheng_jin'
                  "
                >
                  {{ scope.row.estimatedRefundDate | dateFormat }}
                </template>
              </template>
            </el-table-column>
            <el-table-column
              align="left"
              min-width="340"
              label="发票附件上传"
              :show-overflow-tooltip="false"
            >
              <template slot="header">
                <div>发票附件上传</div>
                <div>只能上传pdf或图片.png .jpg .jpeg格式</div>
              </template>
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  class="invoice-file"
                >
                  <template v-if="n.fileType === 'INVOICE'">
                    <div class="invoice-file-left">
                      <div>
                        <a
                          v-if="isShowType(n)"
                          style="text-decoration: revert; color: #409eff; padding-right: 8px"
                          target="_blank"
                          :href="`${filepath}${n.filePath}`"
                        >
                          {{ n.fileName }}
                        </a>
                        <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                          n.fileName
                        }}</span>
                      </div>
                      <div>
                        <span style="color: red" v-if="n.fileInvoiceNoType === 'EXIST'"
                          >发票识别存疑</span
                        >
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="left"
              width="330"
              label="非发票附件上传"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  style="margin-top: 5px"
                >
                  <template v-if="n.fileType === 'NORMAL'">
                    <a
                      v-if="isShowType(n)"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      target="_blank"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                    <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                      n.fileName
                    }}</span>
                  </template>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <!-- 合计内容 -->
          <el-descriptions title="" :column="4" border>
            <el-descriptions-item
              label="原登记单总金额"
              v-if="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT'"
              labelStyle="width:120px"
            >
              {{ form.registrationExpenditureTotalCost | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item
              label="报销合计"
              :span="form.expenseMode == 'REGISTRATION_EXPENDITURE_DOCUMENT' ? 1 : 2"
              labelStyle="width:120px"
            >
              {{ amount | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item :span="2" label="金额大写" labelStyle="width:120px">
              {{ amount | numberParseChina }}
            </el-descriptions-item>
            <template v-if="form.applyExpenseType == 'ADVANCE_PAYMENT'">
              <template v-if="form.status != 100">
                <el-descriptions-item label="总预支费用" labelStyle="width:120px">
                  {{ totalCounteract.sumAdvanceCost | applyAmount }}
                </el-descriptions-item>
                <el-descriptions-item labelStyle="width:150px">
                  <template slot="label">
                    <div>　冲销中的金额</div>
                    <div>（包括本单的冲销）</div>
                  </template>

                  {{ writeOffMoney | applyAmount }}
                </el-descriptions-item></template
              >
              <el-descriptions-item
                label="本次冲销总金额"
                :span="form.status != 100 ? 1 : 2"
                labelStyle="width:120px"
              >
                {{ thisTimeMoney | applyAmount }}
              </el-descriptions-item>
              <el-descriptions-item
                label="报销支付金额"
                :span="form.status != 100 ? 1 : 2"
                labelStyle="width:120px"
              >
                <template v-if="paymentMony">
                  {{ paymentMony | applyAmount }}
                </template>
                <template v-else> 0 </template>
              </el-descriptions-item>
            </template>
          </el-descriptions>
          <el-descriptions
            :column="1"
            direction="vertical"
            style="margin-top: 10px"
            border
            v-if="form.remark"
          >
            <el-descriptions-item>
              <template slot="label">
                <span style="color: #0066ff; font-size: 18px"> 财务确认发放备注 </span>
              </template>
              {{ form.remark }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <span style="color: #0066ff; font-size: 18px"> 发放附件 </span>
              </template>
              <template v-for="item in form.affirmCostFileList">
                <a
                  :key="item.id"
                  target="_blank"
                  style="text-decoration: revert; color: #409eff; display: inline-block; width: 31%"
                  :href="`${filepath}${item.filePath}`"
                  >{{ item.fileName }}</a
                >
              </template>
            </el-descriptions-item>
          </el-descriptions>
          <div style="height: 40px"></div>
          <OpinionArea
            ref="opinionArea"
            :procInstId="form.processInstId"
            :instInvolved="instInvolved"
            :title="'公共费用报销进度'"
          ></OpinionArea>
        </template>

        <!-- 项目付款申请详情 -->
        <template v-if="options.costType == '项目付款申请'">
          <el-form class="form_box" ref="form" :model="form">
            <el-descriptions style="width: 100%" direction="vertical" :column="6" border>
              <el-descriptions-item
                label="单据编号"
                prop="expenseNumber"
                ref="expenseNumber"
                labelClassName="paymentLabelClassName"
                contentClassName="paymentContentClassName"
              >
                <el-form-item prop="expenseNumber" ref="expenseNumber">
                  {{ form.expenseNumber }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="paymentLabelClassName"
                contentClassName="paymentContentClassName"
                label="申请人"
              >
                <el-form-item prop="createName">
                  {{ form.createName }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="paymentLabelClassName"
                contentClassName="paymentContentClassName"
                label="申请日期"
              >
                <el-form-item prop="taskDate">
                  {{ form.taskDate | dateFormat }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                label="部门"
                labelClassName="paymentLabelClassName"
                contentClassName="paymentContentClassName"
              >
                <el-form-item prop="deptId">
                  {{ form.deptName }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="paymentLabelClassName"
                contentClassName="paymentContentClassName"
                :span="form.businessOutSource === 'HAVE' ? 1 : 2"
              >
                <template slot="label">
                  <span style="color: red">*</span>
                  申请单位
                </template>
                <el-form-item prop="companyType">
                  {{ form.companyType | dict(dictData.companyType) }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                label-class-name="businessOutSource paymentLabelClassName"
                content-class-name="businessOutSource paymentContentClassName"
                v-if="form.businessOutSource === 'HAVE'"
              >
                <template slot="label">
                  <span style="color: #000">是否为外协费用</span>
                </template>
                {{ form.businessOutSource === 'HAVE' ? '是' : '否' }}
              </el-descriptions-item>

              <el-descriptions-item
                labelClassName="paymentLabelClassName"
                contentClassName="paymentContentClassName"
              >
                <template slot="label">
                  <span style="color: red">*</span>
                  开户行
                </template>
                <el-form-item prop="openingBank">
                  {{ form.openingBank }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="paymentLabelClassName"
                contentClassName="paymentContentClassName"
              >
                <template slot="label">
                  <span style="color: red">*</span>
                  银行账号
                </template>
                <el-form-item prop="bankAccount">
                  {{ form.bankAccount }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="paymentLabelClassName"
                contentClassName="paymentContentClassName"
                :span="3"
              >
                <template slot="label">
                  <span style="color: red">*</span>
                  收款单位
                </template>
                <el-form-item prop="collectionUnit">
                  {{ form.collectionUnit }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                :span="1"
                label-class-name="businessOutSource paymentLabelClassName"
                content-class-name="businessOutSource paymentContentClassName"
              >
                <template slot="label">
                  <span style="color: #000">费用类别</span>
                </template>
                <span style="color: #000; font-size: 17px">项目付款申请费用</span>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="paymentLabelClassName"
                contentClassName="paymentContentClassName"
                label="付款原因"
                :span="6"
              >
                <template slot="label">
                  <span style="color: red">*</span>
                  付款原因
                </template>
                <el-form-item prop="paymentCause" style="width: 100%">
                  <el-input
                    type="textarea"
                    style="width: 100%"
                    v-model="form.paymentCause"
                    placeholder="请输入付款原因"
                  >
                  </el-input>
                </el-form-item>
              </el-descriptions-item>
            </el-descriptions>
          </el-form>
          <el-descriptions style="margin-top: 10px" title="" :column="2" border>
            <el-descriptions-item labelStyle="width:100px;" contentClassName="projectContentName">
              <template slot="label">
                <span style="color: red">*</span>
                项目名称
              </template>
              <span style="display: inline-block; line-height: 40px; width: 100%">{{
                form.projectName
              }}</span>
            </el-descriptions-item>
            <el-descriptions-item labelStyle="width:110px;">
              <template slot="label"> 项目承接单位 </template>
              <div style="line-height: 40px">
                {{ form.projectCompanyType | dict(dictData.companyType) }}
              </div>
            </el-descriptions-item>
          </el-descriptions>
          <el-table :data="form.detailList" id="table" border :row-style="{ height: '80px' }">
            <el-table-column
              prop="typeOne"
              :show-overflow-tooltip="false"
              label="费用科目一"
              width="160"
            >
              <template slot="header" slot-scope="scope">
                <span style="color: red">*</span> 费用科目一
              </template>
              <template slot-scope="scope">
                <Dictionary disabled :clearable="false" v-model="scope.row.typeOne" code="CBFYZC" />
              </template>
            </el-table-column>
            <el-table-column
              prop="typeTwo"
              :show-overflow-tooltip="false"
              label="费用科目二"
              width="160"
            >
              <template slot-scope="scope">
                <Dictionary
                  disabled
                  v-model="scope.row.typeTwo"
                  :code="scope.row.typeOne"
                  :clearable="false"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="typeThree"
              :show-overflow-tooltip="false"
              label="费用科目三"
              width="160"
            >
              <template slot-scope="scope">
                <Dictionary
                  disabled
                  v-model="scope.row.typeThree"
                  :code="scope.row.typeTwo"
                  :clearable="false"
                />
              </template>
            </el-table-column>
            <el-table-column prop="cost" label="金额 (元)" width="120">
              <template slot="header"> <span style="color: red">*</span>金额 (元) </template>
              <template slot-scope="scope">
                <span>{{ scope.row.cost | applyAmount }}</span>
              </template>
            </el-table-column>
            <el-table-column
              scoped-slot
              prop="invoiceStatus"
              :show-overflow-tooltip="false"
              :label="'事由附件详细说明\n（含参与人员）'"
              width="250"
            >
              <template slot="header">
                <span style="color: red">*</span>事由附件详细说明（含参与人员）
              </template>
              <template slot-scope="scope">
                <el-input
                  placeholder="请输入"
                  type="textarea"
                  disabled
                  :autosize="{ minRows: 2, maxRows: 999 }"
                  v-model="scope.row.invoiceStatus"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="estimatedRefundDate"
              label="预计退回时间"
              width="160"
              v-if="EstimatedShow"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <template
                  v-if="
                    scope.row.typeTwo === 'tou_bao_zheng_jin' ||
                    scope.row.typeTwo === 'lv_yue_bao_zheng_jin'
                  "
                >
                  {{ scope.row.estimatedRefundDate | dateFormat }}
                </template>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              min-width="340"
              label="发票附件上传"
              :show-overflow-tooltip="false"
            >
              <template slot="header">
                <div>发票附件上传</div>
                <div>只能上传pdf或图片.png .jpg .jpeg格式</div>
              </template>
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  class="invoice-file"
                >
                  <template v-if="n.fileType === 'INVOICE'">
                    <div class="invoice-file-left">
                      <div>
                        <a
                          v-if="isShowType(n)"
                          style="text-decoration: revert; color: #409eff; padding-right: 8px"
                          target="_blank"
                          :href="`${filepath}${n.filePath}`"
                        >
                          {{ n.fileName }}
                        </a>
                        <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                          n.fileName
                        }}</span>
                      </div>
                      <div>
                        <span style="color: red" v-if="n.fileInvoiceNoType === 'EXIST'"
                          >发票识别存疑</span
                        >
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="left"
              width="330"
              label="非发票附件上传"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  style="margin-top: 5px"
                >
                  <template v-if="n.fileType === 'NORMAL'">
                    <a
                      v-if="isShowType(n)"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      target="_blank"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                    <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                      n.fileName
                    }}</span>
                  </template>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-descriptions :column="2" border>
            <el-descriptions-item label="合计" labelStyle="width:10%">
              {{ amount | applyAmount }} 元
            </el-descriptions-item>
            <el-descriptions-item label="金额大写" labelStyle="width:10%">
              {{ amount | numberParseChina }}
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions
            :column="1"
            direction="vertical"
            style="margin-top: 10px"
            border
            v-if="form.remark"
          >
            <el-descriptions-item>
              <template slot="label">
                <span style="color: #0066ff; font-size: 18px"> 财务确认发放备注 </span>
              </template>
              {{ form.remark }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <span style="color: #0066ff; font-size: 18px"> 发放附件 </span>
              </template>
              <template v-for="item in form.affirmCostFileList">
                <a
                  :key="item.id"
                  target="_blank"
                  style="text-decoration: revert; color: #409eff; display: inline-block; width: 31%"
                  :href="`${filepath}${item.filePath}`"
                  >{{ item.fileName }}</a
                >
              </template>
            </el-descriptions-item>
          </el-descriptions>
          <div style="height: 40px"></div>
          <OpinionArea
            ref="opinionArea"
            :procInstId="form.processInstId"
            :title="'付款申请进度'"
            :instInvolved="instInvolved"
          ></OpinionArea>
        </template>

        <!-- 商务外协费用详情 -->
        <template v-if="options.costType == '商务外协费用'">
          <el-form class="form_box" ref="form" :model="form">
            <el-descriptions style="width: 100%" direction="vertical" :column="6" border>
              <el-descriptions-item
                labelClassName="outsourceLabelClassName"
                contentClassName="outsourceContentClassName"
                label="单据编号"
                prop="expenseNumber"
                ref="expenseNumber"
              >
                <el-form-item prop="expenseNumber" ref="expenseNumber">
                  {{ form.expenseNumber }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="outsourceLabelClassName"
                contentClassName="outsourceContentClassName"
                label="申请人"
              >
                <el-form-item prop="createName">
                  {{ form.createName }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="outsourceLabelClassName"
                contentClassName="outsourceContentClassName"
                label="申请日期"
              >
                <el-form-item prop="taskDate">
                  {{ form.taskDate | dateFormat }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                label="部门"
                labelClassName="outsourceLabelClassName"
                contentClassName="outsourceContentClassName"
              >
                <el-form-item prop="deptId">
                  {{ form.deptName }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="outsourceLabelClassName"
                contentClassName="outsourceContentClassName"
                :span="2"
              >
                <template slot="label">
                  <span style="color: red">*</span>
                  申请单位
                </template>
                <el-form-item prop="companyType">
                  {{ form.companyType | dict(dictData.companyType) }}
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item
                labelClassName="outsourceLabelClassName"
                contentClassName="outsourceContentClassName"
              >
                <template slot="label">
                  <span style="color: red">*</span>
                  开户行
                </template>
                <el-form-item prop="openingBank">
                  {{ form.openingBank }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="outsourceLabelClassName"
                contentClassName="outsourceContentClassName"
              >
                <template slot="label">
                  <span style="color: red">*</span>
                  银行账号
                </template>
                <el-form-item prop="bankAccount"> >{{ form.bankAccount }} </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="outsourceLabelClassName"
                contentClassName="outsourceContentClassName"
                :span="3"
              >
                <template slot="label">
                  <span style="color: red">*</span>
                  收款单位
                </template>
                <el-form-item prop="collectionUnit">
                  {{ form.collectionUnit }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                :span="1"
                label-class-name="businessOutSource outsourceLabelClassName"
                content-class-name="businessOutSource outsourceContentClassName"
              >
                <template slot="label">
                  <span style="color: #000">费用类别</span>
                </template>
                <span style="color: #000; font-size: 17px">外协费用</span>
              </el-descriptions-item>
              <el-descriptions-item
                labelClassName="outsourceLabelClassName"
                contentClassName="outsourceContentClassName"
                label="付款原因"
                :span="6"
              >
                <template slot="label">
                  <span style="color: red">*</span>
                  付款原因
                </template>
                <el-form-item prop="paymentCause" style="width: 100%">
                  <el-input
                    type="textarea"
                    style="width: 100%"
                    v-model="form.paymentCause"
                    placeholder="请输入付款原因"
                    disabled
                  >
                  </el-input>
                </el-form-item>
              </el-descriptions-item>
            </el-descriptions>
          </el-form>
          <el-descriptions style="margin-top: 10px" title="" :column="3" border>
            <el-descriptions-item labelStyle="width:100px;">
              <template slot="label">
                <span style="color: red">*</span>
                商务名称
              </template>
              <span style="display: inline-block; line-height: 40px; width: 70%">{{
                form.businessName
              }}</span>
            </el-descriptions-item>
          </el-descriptions>
          <el-table :data="form.detailList" id="table" border :row-style="{ height: '80px' }">
            <el-table-column
              prop="typeOne"
              :show-overflow-tooltip="false"
              label="费用科目一"
              width="160"
            >
              <template slot="header" slot-scope="scope">
                <span style="color: red">*</span> 费用科目一
              </template>
              <template slot-scope="scope">
                <Dictionary disabled :clearable="false" v-model="scope.row.typeOne" code="CBFYZC" />
              </template>
            </el-table-column>
            <el-table-column
              prop="typeTwo"
              :show-overflow-tooltip="false"
              label="费用科目二"
              width="160"
            >
              <template slot-scope="scope">
                <Dictionary
                  disabled
                  v-model="scope.row.typeTwo"
                  :code="scope.row.typeOne"
                  :clearable="false"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="typeThree"
              :show-overflow-tooltip="false"
              label="费用科目三"
              width="160"
            >
              <template slot-scope="scope">
                <Dictionary
                  disabled
                  v-model="scope.row.typeThree"
                  :code="scope.row.typeTwo"
                  :clearable="false"
                />
              </template>
            </el-table-column>
            <el-table-column prop="cost" label="金额 (元)" width="120">
              <template slot="header"> <span style="color: red">*</span>金额 (元) </template>
              <template slot-scope="scope">
                {{ scope.row.cost | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              scoped-slot
              prop="invoiceStatus"
              :show-overflow-tooltip="false"
              :label="'事由附件详细说明\n（含参与人员）'"
              width="250"
            >
              <template slot="header">
                <span style="color: red">*</span>事由附件详细说明（含参与人员）
              </template>
              <template slot-scope="scope">
                <el-input
                  placeholder="请输入"
                  type="textarea"
                  disabled
                  :autosize="{ minRows: 2, maxRows: 999 }"
                  v-model="scope.row.invoiceStatus"
                ></el-input>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="estimatedRefundDate"
              label="预计退回时间"
              width="160"
              v-if="EstimatedShow"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <template
                  v-if="
                    scope.row.typeTwo === 'tou_bao_zheng_jin' ||
                    scope.row.typeTwo === 'lv_yue_bao_zheng_jin'
                  "
                >
              {{ scope.row.estimatedRefundDate | dateFormat }}
                </template>
              </template>
            </el-table-column> -->
            <el-table-column
              align="left"
              min-width="340"
              label="发票附件上传"
              :show-overflow-tooltip="false"
            >
              <template slot="header">
                <div>发票附件上传</div>
                <div>只能上传pdf或图片.png .jpg .jpeg格式</div>
              </template>
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  class="invoice-file"
                >
                  <template v-if="n.fileType === 'INVOICE'">
                    <div class="invoice-file-left">
                      <div>
                        <a
                          v-if="isShowType(n)"
                          style="text-decoration: revert; color: #409eff; padding-right: 8px"
                          target="_blank"
                          :href="`${filepath}${n.filePath}`"
                        >
                          {{ n.fileName }}
                        </a>
                        <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                          n.fileName
                        }}</span>
                      </div>
                      <div>
                        <span style="color: red" v-if="n.fileInvoiceNoType === 'EXIST'"
                          >发票识别存疑</span
                        >
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="left"
              width="330"
              label="非发票附件上传"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  style="margin-top: 5px"
                >
                  <template v-if="n.fileType === 'NORMAL'">
                    <a
                      v-if="isShowType(n)"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      target="_blank"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                    <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                      n.fileName
                    }}</span>
                  </template>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-descriptions :column="2" border>
            <el-descriptions-item label="合计" labelStyle="width:10%">
              {{ amount | applyAmount }} 元
            </el-descriptions-item>
            <el-descriptions-item label="金额大写" labelStyle="width:10%">
              {{ amount | numberParseChina }}
            </el-descriptions-item>
          </el-descriptions>

          <el-descriptions
            :column="1"
            direction="vertical"
            style="margin-top: 10px"
            border
            v-if="form.remark"
          >
            <el-descriptions-item>
              <template slot="label">
                <span style="color: #0066ff; font-size: 18px"> 财务确认发放备注 </span>
              </template>
              {{ form.remark }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <span style="color: #0066ff; font-size: 18px"> 发放附件 </span>
              </template>
              <template v-for="item in form.affirmCostFileList">
                <a
                  :key="item.id"
                  target="_blank"
                  style="text-decoration: revert; color: #409eff; display: inline-block; width: 31%"
                  :href="`${filepath}${item.filePath}`"
                  >{{ item.fileName }}</a
                >
              </template>
            </el-descriptions-item>
          </el-descriptions>
          <div style="height: 40px"></div>
          <OpinionArea
            ref="opinionArea"
            :procInstId="form.processInstId"
            :title="'外协费用申请进度'"
            :instInvolved="instInvolved"
          ></OpinionArea>
        </template>

        <!-- 公共费用付款申请详情 -->
        <template v-if="options.costType == '公共付款申请'">
          <el-form class="form_box" ref="form" :model="form">
            <el-descriptions style="width: 100%" direction="vertical" :column="5" border>
              <el-descriptions-item label="单据编号" prop="expenseNumber" ref="expenseNumber">
                <el-form-item prop="expenseNumber" ref="expenseNumber">
                  {{ form.expenseNumber }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="申请人">
                <el-form-item prop="createName">
                  {{ form.createName }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="申请日期">
                <el-form-item prop="taskDate">
                  {{ form.taskDate | dateFormat }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item label="费用所属部门">
                <el-form-item prop="deptId">
                  {{ form.deptName }}
                </el-form-item>
              </el-descriptions-item>

              <el-descriptions-item>
                <template slot="label">
                  <span style="color: red">*</span>
                  申请单位
                </template>
                <el-form-item prop="companyType">
                  {{ form.companyType | dict(dictData.companyType) }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color: red">*</span>
                  开户行
                </template>
                <el-form-item prop="openingBank">
                  {{ form.openingBank }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color: red">*</span>
                  银行账号
                </template>
                <el-form-item prop="bankAccount">
                  {{ form.bankAccount }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item :span="2">
                <template slot="label">
                  <span style="color: red">*</span>
                  收款单位
                </template>
                <el-form-item prop="collectionUnit">
                  {{ form.collectionUnit }}
                </el-form-item>
              </el-descriptions-item>
              <el-descriptions-item
                :span="1"
                label-class-name="businessOutSource"
                content-class-name="businessOutSource"
              >
                <template slot="label">
                  <span style="color: #000">费用类别</span>
                </template>
                <span style="color: #000; font-size: 17px">公共付款申请费用</span>
              </el-descriptions-item>
              <el-descriptions-item label="付款原因" :span="5">
                <template slot="label">
                  <span style="color: red">*</span>
                  付款原因
                </template>
                <el-form-item prop="paymentCause" style="width: 100%">
                  {{ form.paymentCause }}
                </el-form-item>
              </el-descriptions-item>
            </el-descriptions>
          </el-form>

          <el-table :data="form.detailList" border>
            <el-table-column
              prop="typeOne"
              :show-overflow-tooltip="false"
              label="费用科目一"
              width="170"
            >
              <template slot="header"> <span style="color: red">*</span> 费用科目一 </template>
              <template slot-scope="scope">
                <Dictionary :disabled="true" v-model="scope.row.typeOne" code="CBFYZC" />
              </template>
            </el-table-column>
            <el-table-column
              prop="typeTwo"
              :show-overflow-tooltip="false"
              label="费用科目二"
              width="170"
            >
              <template slot-scope="scope">
                <Dictionary
                  :disabled="true"
                  v-model="scope.row.typeTwo"
                  :code="scope.row.typeOne"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="typeThree"
              :show-overflow-tooltip="false"
              label="费用科目三"
              width="170"
            >
              <template slot-scope="scope">
                <Dictionary
                  :disabled="true"
                  v-model="scope.row.typeThree"
                  :code="scope.row.typeTwo"
                />
              </template>
            </el-table-column>

            <el-table-column prop="cost" label="金额 (元)" width="120">
              <template slot="header"> <span style="color: red">*</span>金额 (元) </template>
              <template slot-scope="scope">
                {{ scope.row.cost | applyAmount }}
              </template>
            </el-table-column>

            <el-table-column
              scoped-slot
              prop="invoiceStatus"
              :show-overflow-tooltip="false"
              :label="'事由附件详细说明\n（含参与人员）'"
              width="250"
            >
              <template slot="header">
                <span style="color: red">*</span>事由附件详细说明（含参与人员）
              </template>
              <template slot-scope="scope">
                <el-input
                  disabled
                  placeholder="请输入"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 999 }"
                  v-model="scope.row.invoiceStatus"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="estimatedRefundDate"
              label="预计退回时间"
              width="160"
              v-if="EstimatedShow"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <template
                  v-if="
                    scope.row.typeTwo === 'tou_bao_zheng_jin' ||
                    scope.row.typeTwo === 'lv_yue_bao_zheng_jin'
                  "
                >
                  {{ scope.row.estimatedRefundDate | dateFormat }}
                </template>
              </template>
            </el-table-column>
            <el-table-column
              align="left"
              min-width="340"
              label="发票附件上传"
              :show-overflow-tooltip="false"
            >
              <template slot="header">
                <div>发票附件上传</div>
                <div>只能上传pdf或图片.png .jpg .jpeg格式</div>
              </template>
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  class="invoice-file"
                >
                  <template v-if="n.fileType === 'INVOICE'">
                    <div class="invoice-file-left">
                      <div>
                        <a
                          v-if="isShowType(n)"
                          style="text-decoration: revert; color: #409eff; padding-right: 8px"
                          target="_blank"
                          :href="`${filepath}${n.filePath}`"
                        >
                          {{ n.fileName }}
                        </a>
                        <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                          n.fileName
                        }}</span>
                      </div>
                      <div>
                        <span style="color: red" v-if="n.fileInvoiceNoType === 'EXIST'"
                          >发票识别存疑</span
                        >
                      </div>
                    </div>
                  </template>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="left"
              width="330"
              label="非发票附件上传"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <div
                  v-for="(n, index) in scope.row.expenseFileList"
                  :key="index"
                  style="margin-top: 5px"
                >
                  <template v-if="n.fileType === 'NORMAL'">
                    <a
                      v-if="isShowType(n)"
                      style="text-decoration: revert; color: #409eff; padding-right: 8px"
                      target="_blank"
                      :href="`${filepath}${n.filePath}`"
                    >
                      {{ n.fileName }}
                    </a>
                    <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                      n.fileName
                    }}</span>
                  </template>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <el-descriptions title="" :column="3" border>
            <el-descriptions-item label="合计" labelStyle="width:10%">
              {{ amount | applyAmount }}
            </el-descriptions-item>
            <el-descriptions-item label="金额大写" labelStyle="width:10%">
              {{ amount | numberParseChina }}
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions
            :column="1"
            direction="vertical"
            style="margin-top: 10px"
            border
            v-if="form.remark"
          >
            <el-descriptions-item>
              <template slot="label">
                <span style="color: #0066ff; font-size: 18px"> 财务确认发放备注 </span>
              </template>
              {{ form.remark }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <span style="color: #0066ff; font-size: 18px"> 发放附件 </span>
              </template>
              <template v-for="item in form.affirmCostFileList">
                <a
                  :key="item.id"
                  target="_blank"
                  style="text-decoration: revert; color: #409eff; display: inline-block; width: 31%"
                  :href="`${filepath}${item.filePath}`"
                  >{{ item.fileName }}</a
                >
              </template>
            </el-descriptions-item>
          </el-descriptions>
          <div style="height: 40px"></div>
          <OpinionArea
            ref="opinionArea"
            :procInstId="form.processInstId"
            :title="'付款申请进度'"
            :instInvolved="instInvolved"
          ></OpinionArea>
        </template>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="returnDialogFn">返回</el-button>
      </div>
    </EditDialog>

    <DialogImg
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :filepath="filepath"
      :imgList="imgList"
      :img_index="img_index"
    ></DialogImg>
  </div>
</template>

<script>
export default {
  name: 'CostDetailDialog',
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
  },
  data() {
    return {
      img_index: 0, //附件图片预览下标
      dialogVisible: false, //图片预览
      loading: false,
      filepath: process.env.VUE_APP_FILEPATH,
      form: {},
      dictData: {
        companyType: [],
        disbursementType: [],
      },
      imgList: [],
      totalCounteract: {
        sumAdvanceCost: 0, //经费预支总合计
        sumCounteractCost: 0, //	冲销中金额合计
      },
      instInvolved: {}, //当前进度处理人数组
      applyExpenseType: [], //付款方式字典
      expenseMode: [], //报销方式
    }
  },
  props: {
    costDialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    options: {
      type: Object,
      default: () => {
        return {}
      },
      required: true,
    },
  },
  created() {
    this.$api.dict
      .listSysDictData('COMPANY_TYPE', true)
      .then(res => {
        this.dictData.companyType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('APPLY_EXPENSE_TYPE', true)
      .then(res => {
        this.applyExpenseType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('EXPENSE_MODE', true)
      .then(res => {
        this.expenseMode = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_DISBURSEMENT_TYPE', true)
      .then(res => {
        this.dictData.disbursementType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  computed: {
    // 报销单金额合计
    amount() {
      let sum = 0
      if (this.form.detailList) {
        sum = this.form.detailList.reduce((total, item) => (total += Number(item.cost)), 0)
      }
      return sum
    },
    // 冲销中的金额
    writeOffMoney() {
      let num = 0
      num = this.totalCounteract.sumCounteractCost + this.thisTimeMoney
      return num
    },
    // 本次冲销金额
    thisTimeMoney() {
      let num = 0
      if (this.form.counteractExpenseList) {
        this.form.counteractExpenseList.forEach(v => {
          num += Number(v.counteractCost)
        })
      }
      return num
    },
    // 报销支付金额
    paymentMony() {
      let num = 0
      num = this.amount - this.thisTimeMoney
      if (num > 0) {
        return num
      } else {
        return 0
      }
    },
    EstimatedShow() {
      if (this.form.detailList) {
        const item = this.form.detailList.find(
          v => v.typeTwo === 'tou_bao_zheng_jin' || v.typeTwo === 'lv_yue_bao_zheng_jin'
        )

        if (item) {
          return true
        } else {
          return false
        }
      }
      return false
    },
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  watch: {
    costDialog: {
      // deep: true,
      immediate: true,
      handler: function (val) {
        if (!val) {
          this.$emit('returnDialogFn')
        }
      },
    },
    options: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val.costType == '项目报销') {
          this.projectCostFn(val.projectExpenseId)
        } else if (val.costType == '商务报销') {
          this.businessCostFn(val.businessExpenseId)
        } else if (val.costType == '公共报销') {
          this.publicCostFn(val.publicSpendingExpenseId)
        } else if (val.costType == '项目付款申请') {
          this.projectPaymentFn(val.projectPaymentApplicationId)
        } else if (val.costType == '商务外协费用') {
          this.businessPaymentFn(val.businessPaymentApplicationId)
        } else if (val.costType == '公共付款申请') {
          this.publicPaymentFn(val.publicSpendingPaymentApplicationId)
        }
      },
    },
  },
  methods: {
    async businessCostFn(id) {
      this.loading = true
      const res = await this.$api.businessExpense.selectDetailById(id)
      if (res.data) {
        this.dataProcessing(res)
        this.loading = false
      } else {
        this.loading = false
      }
    },
    async projectCostFn(id) {
      this.loading = true
      const res = await this.$api.projectCost.selectDetailById(id)
      if (res.data) {
        this.dataProcessing(res)
        this.loading = false
      } else {
        this.loading = false
      }
    },
    async publicCostFn(id) {
      this.loading = true
      const res = await this.$api.publicSpending.selectDetailById(id)
      if (res.data) {
        this.dataProcessing(res)
        this.loading = false
      } else {
        this.loading = false
      }
    },
    async projectPaymentFn(id) {
      this.loading = true
      const res = await this.$api.payment.selectDetailById(id)
      if (res.data) {
        this.dataProcessing(res)
        this.loading = false
      } else {
        this.loading = false
      }
    },
    async businessPaymentFn(id) {
      this.loading = true
      const res = await this.$api.businessOutsourceCost.selectDetailById(id)
      if (res.data) {
        this.dataProcessing(res)
        this.loading = false
      } else {
        this.loading = false
      }
    },
    async publicPaymentFn(id) {
      this.loading = true
      const res = await this.$api.publicPayment.selectDetailById(id)
      if (res.data) {
        this.dataProcessing(res)
        this.loading = false
      } else {
        this.loading = false
      }
    },
    dataProcessing(res = {}) {
      this.form = res.data
      if (res.data.detailList) {
        this.imgList = []
        res.data.detailList.forEach(item => {
          item.expenseFileList.forEach(v => {
            if (!this.isShowType(v)) {
              this.imgList.push(v)
            }
          })
        })
      }
      this.totalCounteract.sumAdvanceCost = this.form.sumAdvanceCost ? this.form.sumAdvanceCost : 0
      this.totalCounteract.sumCounteractCost = this.form.sumCounteractCost
        ? this.form.sumCounteractCost
        : 0
      this.instInvolved = {
        instInvolvedUserName: res.data.instInvolvedUserName ? res.data.instInvolvedUserName : [],
        status: res.data.status,
      }
    },
    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },

    imgIsShow(n, index) {
      this.img_index = 0
      this.imgList.forEach((v, i) => {
        if (v.temporaryId) {
          if (v.temporaryId === n.temporaryId) {
            this.img_index = i
          }
        } else if (v.id === n.id || (v.fileName === n.fileName && v.filePath === n.filePath)) {
          this.img_index = i
        }
      })
      this.dialogVisible = true
    },

    // 关闭详情弹框
    returnDialogFn() {
      this.$emit('returnDialogFn')
    },
  },
}
</script>

<style lang="scss" scoped>
.img_txt {
  text-decoration: underline;
  color: #409eff;
  &:hover {
    cursor: pointer;
  }
  .content {
    padding: 10px;
  }
}

/deep/.businessOutSource {
  //费用类型背景色
  background-color: #b2f3fe !important;
  border: 1px solid #ddd !important;
}
.dictionary {
  padding-right: 0;
  padding-bottom: 0;
}
</style>
